import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "プリファレンスセンター",
    tagline: "プリファレンスセンターのデザインを自在にコントロール"
}

export const features = {
    image: (<StaticImage title='プリファレンスセンター' src={"../../static/images/feature/preference-center/featureImage.png"} width={530} quality={100} alt="Preference Center Feature Image"  />),
    contents: [
        {
            title: "ユニバーサルプリファレンスセンター",
            paragraph: <p>Secure Privacyは、いくつかのデザインテンプレートとオプションを提供していますが、CSSを使ってすぐ変更することもできます。GDPR、CCPA、LGPDを確実に遵守できるようにするために、ユーザーがクッキーのプリファレンスを設定できるようにし、さらに先進的なクッキーバナーソリューションで安心感を得られるようにします。</p>
        },
    ]
}

